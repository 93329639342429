<template>
  <div ref="content">
    <header class="d-flex flex-row justify-space-between align-center">
      <div
        class="title-base-header"
      >Конфигурация
      </div>
      <BaseBtn
        title="Сохранить"
        @click="saveConfig"
        small
        class="mr-2"
        color="primary"
      />
    </header>
    <v-progress-linear
      v-if="isLoading.data"
      size="35"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <main class="ml-2 mt-5">
      <div
        class="d-flex flex-row align-center"
        v-if="!isLoading.data"
      >
        <v-checkbox
          hide-details
          class="ma-0"
          label="Выбрать всё"
          v-model="checkAll"
        ></v-checkbox>
      </div>
      <div
        v-for="(item, index) in objectAttributes"
        :key="item.id"
        class="d-flex flex-row pa-2 pb-3 align-center justify-space-between wrapper-item rounded"
        :class="hiddenIds.find((element) => element === item.name) ? 'checked': ''"
      >
        <p class="ma-0">{{ item.label }}</p>
        <div class="d-flex flex-row align-center">
          <v-checkbox
            hide-details
            class="ma-0"
            label="Включить"
            @change="handleCheckbox($event, index)"
            v-model="item.isActive"
          ></v-checkbox>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import BaseHeader from '@/components/base/BaseDetail/BaseHeader'
import BaseBtn from '@/components/base/UI/BaseBtn'
import detail from '@/components/mixins/detail'
import { filterAttributes, flatenObject, sortWithNull } from '@/components/utils/common'

export default {
  name: 'ConfigObjectDetail',
  mixins: [detail],
  components: { BaseBtn, BaseHeader },
  data () {
    return {
      detail: null,
      hiddenIds: [],
      checkedAll: false,
      objectAttributes: []
    }
  },
  computed: {
    checkAll: {
      set (val) { // чекнуть все объекты
        this.objectAttributes.forEach((item, index) => {
          this.$set(this.objectAttributes[index], 'isActive', val)
        })
        this.checkedAll = !this.checkedAll
      },
      get () {
        return this.checkedAll
      }
    },
    modelIdName () {
      return 'objectId'
    },
    controllerName () {
      return 'constructor'
    },
    checkedItems () { //объект для отправки на сервер
      const result = {}
      this.objectAttributes.forEach((item) => {
        result[item.name] = Boolean(item.isActive)
      })
      return result
    }
  },
  watch: {
    objectAttributes: {
      handler () {//чекнуть чекбокс, если все объекты выбраны
        this.checkedAll = Object.values(this.checkedItems).every((item) => item === true)
      },
      deep: true
    }
  },
  methods: {
    setObjectAttributes () {
      const objectAttributes = flatenObject(filterAttributes(this.detail?.eavAttributes, ['id', 'element_number', 'state']), 'relatedAttributes')
      this.objectAttributes = objectAttributes
    },
    async saveConfig () {
      this.isLoading.data = true
      const response = await this.$store.dispatch('api/put', {
        url: 'organization/attributes',
        data: {
          'organization_id': this.$route?.params?.id,
          eav_entity: this.$route?.params?.objectId,
          data: this.checkedItems
        }
      })
      if (!response.success) {
        await this.$store.dispatch('systemMessages/handleError', 'Не удалось сохранить конфигурацию')
      } else {
        await this.$store.dispatch('systemMessages/success', 'Сохранено')
      }
      this.isLoading.data = false
    },
    handleCheckbox (event, index) { //на обычный чекбокс
      this.$set(this.objectAttributes[index], 'isActive', event)
      if (!event) {
        this.checkedAll = false
      }
    },
    async fillData () {
      this.isLoading.data = true
      const response = await this.$store.dispatch('api/get', {
        url: this.controllerName + '/detail/' + this.$route.params[this.modelIdName],
        params: {
          'organization_id': this.$route?.params?.id
        }
      })
      if (response.success) {
        this.detail = response.data
        this.setObjectAttributes()
      }
      this.isLoading.data = false
    }
  }
}
</script>

<style scoped>
  .wrapper-item {
    background-color: white;
    max-width: 40vw;
    gap: 5px;
  }
  main {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .checked {
    background-color: #c0deec;
    color: #226580;
  }

</style>